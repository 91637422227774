/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'adapter/next/router';
import { Box, theme } from '@konsus/superside-kit';
import { TextInput } from '@superside/ui';
import styles from './ReferralCodeInput.module.css';

export interface ReferralCodeInputProps {
  targetReferralLink?: string;
  referralName?: string;
}

export const ReferralCodeInput: React.FC<ReferralCodeInputProps> = ({
  targetReferralLink,
  referralName = ''
}) => {
  const router = useRouter();
  const query: any = router.query;
  const referralCode: string = query[referralName];
  const inputRef = useRef<HTMLInputElement>(null);
  const [copyText, setCopyText] = useState(referralCode);
  const [isCopied, setCopied] = useState(false);

  useEffect(() => {
    if (referralCode) {
      setCopyText(`${targetReferralLink}${referralCode}`);
    }
  }, [referralCode, targetReferralLink]);

  if (!targetReferralLink || !copyText) {
    return null;
  }

  const handleClick = async () => {
    try {
      const input = inputRef.current;

      input?.focus();
      input?.select();

      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);

      await navigator.clipboard.writeText(copyText);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  return (
    <Box
      flexBox={false}
      className={`${styles.refLinkWrapper} [&_input]:caret-black-100/0 [&_input]:text-light [&_input_::selection]:text-dark [&_input]:text-bor-lg -mb-3 mt-2 h-fit w-full [&_input]:mr-3 [&_input]:w-full [&_input]:cursor-pointer [&_input]:transition-all [&_input]:duration-[0.25s] [&_input]:ease-[ease-in-out] [&_input_::selection]:bg-green-500`}
      onClick={handleClick}
    >
      <TextInput
        ref={inputRef}
        type='text'
        value={copyText}
        onChange={(e) => e.preventDefault()}
        dir='rtl'
      />
      <Box
        style={{
          transition: 'opacity 0.15s ease-in-out',
          opacity: isCopied ? 1 : 0,
          fontSize: theme.text.small.size,
          marginTop: 6
        }}
      >
        Copied to clipboard!
      </Box>
    </Box>
  );
};

export default ReferralCodeInput;
