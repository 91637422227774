import React from 'react';

export const BubbleSvg = ({ scale = 1 }) => {
  return (
    <>
      <g style={{ mixBlendMode: 'screen' }} transform={`scale(${scale})`}>
        <ellipse cx='62' cy='62' rx='45' ry='45' fill='url(#paint0_radial_3544_56823)' />
        <circle
          cx='62'
          cy='62'
          r='45'
          transform='rotate(125 62 62)'
          fill='url(#paint1_radial_3544_56823)'
        />
        <circle cx='62' cy='62' r='45' fill='url(#paint2_radial_3544_56823)' />
        <circle
          cx='62'
          cy='62'
          r='45'
          transform='rotate(-42 62 62)'
          fill='url(#paint3_radial_3544_56823)'
        />
        <circle
          cx='62'
          cy='62'
          r='45'
          transform='rotate(135 62 62)'
          fill='url(#paint4_radial_3544_56823)'
        />
        <circle
          cx='62'
          cy='62'
          r='45'
          transform='rotate(56 62 62)'
          fill='url(#paint5_radial_3544_56823)'
        />
        <circle
          cx='62'
          cy='62'
          r='45'
          transform='rotate(82 62 62)'
          fill='url(#paint6_radial_3544_56823)'
        />
        <circle
          cx='62'
          cy='62'
          r='45'
          transform='rotate(-52 62 62)'
          fill='url(#paint7_radial_3544_56823)'
        />
        <circle
          cx='62'
          cy='62'
          r='45'
          transform='rotate(170 62 62)'
          fill='url(#paint8_radial_3544_56823)'
        />
        <ellipse
          cx='62'
          cy='62'
          rx='45'
          ry='45'
          transform='rotate(-58 62 62)'
          fill='url(#paint9_radial_3544_56823)'
        />
      </g>
      <g filter='url(#filter0_f_3544_56823)'>
        <circle cx='62' cy='62' r='26' fill='#0B1926' fillOpacity='0.25' />
      </g>
      <defs>
        <filter
          id='filter0_f_3544_56823'
          x='0.6'
          y='0.6'
          width='122.8'
          height='122.8'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='17.5' result='effect1_foregroundBlur_3544_56823' />
        </filter>
        <radialGradient
          id='paint0_radial_3544_56823'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(61.8247 62.1741) rotate(90) scale(105.861 105.861)'
        >
          <stop offset='0.349183' stopColor='#F1F1F2' stopOpacity='0' />
          <stop offset='0.543581' stopColor='#F1F1F2' stopOpacity='0.183333' />
          <stop offset='0.654666' stopColor='#F1F1F2' stopOpacity='0.5' />
        </radialGradient>
        <radialGradient
          id='paint1_radial_3544_56823'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(65.2062 54.9887) rotate(92.0607) scale(93.9733 117.921)'
        >
          <stop offset='0.364595' stopColor='#0091A9' stopOpacity='0' />
          <stop offset='1' stopColor='#0091A9' />
        </radialGradient>
        <radialGradient
          id='paint2_radial_3544_56823'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(61.8247 62.1741) rotate(90) scale(86.7255 109.406)'
        >
          <stop offset='0.360731' stopColor='#7272D3' stopOpacity='0' />
          <stop offset='0.735201' stopColor='#3535AA' />
        </radialGradient>
        <radialGradient
          id='paint3_radial_3544_56823'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(60.2394 44.5461) rotate(89.1329) scale(104.367 110.496)'
        >
          <stop offset='0.495688' stopColor='#3535AA' stopOpacity='0' />
          <stop offset='0.764237' stopColor='#3535AA' />
        </radialGradient>
        <radialGradient
          id='paint4_radial_3544_56823'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(60.245 44.5457) rotate(89.1329) scale(104.367 110.496)'
        >
          <stop offset='0.21875' stopColor='#3535AA' stopOpacity='0' />
          <stop offset='0.416667' stopColor='#335CFC' stopOpacity='0.11' />
          <stop offset='0.614583' stopColor='#3535AA' stopOpacity='0' />
        </radialGradient>
        <radialGradient
          id='paint5_radial_3544_56823'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(61.8284 62.1759) rotate(90) scale(86.7254 109.406)'
        >
          <stop offset='0.406944' stopColor='#FF19A3' stopOpacity='0' />
          <stop offset='1' stopColor='#FF19A3' />
        </radialGradient>
        <radialGradient
          id='paint6_radial_3544_56823'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(60.3044 75.7663) rotate(82.3707) scale(80.8366 81.7051)'
        >
          <stop offset='0.656867' stopColor='#FF827F' stopOpacity='0' />
          <stop offset='0.873223' stopColor='#FF635F' />
        </radialGradient>
        <radialGradient
          id='paint7_radial_3544_56823'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(66.787 68.5061) rotate(88.0786) scale(127.694 129.066)'
        >
          <stop offset='0.342248' stopColor='#AAF3FF' stopOpacity='0' />
          <stop offset='0.644995' stopColor='#55E7FF' />
        </radialGradient>
        <radialGradient
          id='paint8_radial_3544_56823'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(63.5686 69.0345) rotate(86.624) scale(127.316 146.058)'
        >
          <stop offset='0.380208' stopColor='white' stopOpacity='0' />
          <stop offset='0.609375' stopColor='white' />
        </radialGradient>
        <radialGradient
          id='paint9_radial_3544_56823'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(63.5643 69.0328) rotate(86.624) scale(127.316 146.058)'
        >
          <stop offset='0.364583' stopColor='white' stopOpacity='0' />
          <stop offset='0.510417' stopColor='white' />
        </radialGradient>
      </defs>
    </>
  );
};
