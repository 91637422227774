import React from 'react';
import dynamic from 'adapter/next/dynamic';
import { cn, Heading } from '@superside/ui';
import { Box, ExternalContentWrapper } from '@konsus/superside-kit';
import { SanityBlockComponent } from '@konsus/sanity-components/src/SanityBlockComponent';
import NextImage from '@konsus/image';
import { PageSection } from '../../components/PageSection';
import { SectionButton } from '../../components/SectionButton';
import { type IVisualAsset, VisualAsset } from '../../components/VisualAsset';
import ReferralCodeInput from '../../components/ReferralCodeInput';
import Superscript from '../../components/Superscript';
import styles from '../HeroFormSection/HeroFormSection.module.css';
import heroLeftStyles from './index.module.css';

const EmailCapturingForm = dynamic(
  () => import(/* webpackChunkName: "tier-0" */ '../../components/EmailCapturingForm')
);

type VariantType = 'withButton' | 'withForm';

export interface ButtonProps {
  ctaText: string;
  ctaLink: string;
  ctaUrl?: string;
  isTargetBlank?: boolean;
  openScheduler?: boolean;
  routerName?: string;
  formId?: string;
  ctaType?: 'url' | 'modalWithProgressiveForm';
}

interface HeroLeftProps {
  superscriptText?: string;
  headerText: string;
  headerRichText?: { content: Array<any> };
  richText?: any;
  heroRight?: boolean;
  variant: VariantType;
  button?: ButtonProps;
  tertiaryButton?: ButtonProps;
  heroVisualAsset?: IVisualAsset;
  form?: {
    hubspotFormId?: string;
    placeholderText?: string;
    submitButtonText?: string;
  };
  image?: any;
  targetReferralLink?: string;
  referralName?: string;
}

export const HeroLeft: React.FunctionComponent<HeroLeftProps> = (props) => {
  const {
    superscriptText,
    headerText,
    headerRichText,
    richText,
    heroRight = false,
    image,
    variant,
    form,
    button,
    tertiaryButton,
    heroVisualAsset,
    targetReferralLink,
    referralName,
    ...rest
  } = props;

  const heroVisualAssetKeys =
    typeof heroVisualAsset === 'object' ? Object.keys(heroVisualAsset!) : [];
  const heroVisualAssetNoSource =
    heroVisualAssetKeys.length === 2 &&
    heroVisualAssetKeys.includes('_type') &&
    heroVisualAssetKeys.includes('mediaType');

  const showAsset = (heroVisualAsset && !heroVisualAssetNoSource) || image;

  const primaryButtonExists = button?.ctaText && button?.ctaLink;
  const tertiaryButtonExists = tertiaryButton?.ctaText && tertiaryButton?.ctaLink;

  return (
    <PageSection {...rest} className={styles.dark}>
      <Box
        className={cn(
          '768:flex-row 768:items-center 768:justify-between',
          heroRight && '768:flex-row-reverse flex-col-reverse'
        )}
      >
        <Box
          className={cn(
            '1024:w-[calc(50%_-_39px)] 1440:w-[calc(50%_-_69px)] 768:w-[calc(50%_-_27px)]',
            showAsset && '1024:mb-0 768:mb-30 mb-16'
          )}
        >
          {superscriptText ? <Superscript content={superscriptText} /> : null}

          {headerRichText ? (
            <SanityBlockComponent
              blocks={headerRichText}
              renderNormalAsHeading={{ as: 'h1', level: 'h2' }}
            />
          ) : headerText ? (
            <Heading dangerouslySetInnerHTML={{ __html: headerText }} as='h1' level='h2' />
          ) : null}

          {richText ? (
            <Box margin={{ top: '4' }} className={styles.contentStyle}>
              <ExternalContentWrapper>
                <SanityBlockComponent blocks={richText} />
              </ExternalContentWrapper>

              <ReferralCodeInput
                targetReferralLink={targetReferralLink}
                referralName={referralName}
              />
            </Box>
          ) : null}

          {variant !== 'withButton' || primaryButtonExists || tertiaryButtonExists ? (
            <Box margin={{ top: '8' }}>
              {variant !== 'withButton' ? (
                <Box>
                  <EmailCapturingForm
                    redirectLink='/get-started'
                    buttonLabel={form?.submitButtonText}
                    placeholder={form?.placeholderText}
                    formId={form?.hubspotFormId}
                  />
                </Box>
              ) : primaryButtonExists || tertiaryButtonExists ? (
                <Box className={heroLeftStyles.buttonsBox} gap='8'>
                  {button?.ctaText && button?.ctaLink ? (
                    <SectionButton primary href={button.ctaLink} label={button.ctaText} />
                  ) : null}

                  {tertiaryButton?.ctaText && tertiaryButton?.ctaLink ? (
                    <SectionButton
                      className={heroLeftStyles.tertiaryLink}
                      plain
                      href={tertiaryButton.ctaLink}
                      label={tertiaryButton.ctaText}
                    />
                  ) : null}
                </Box>
              ) : null}
            </Box>
          ) : null}
        </Box>

        {showAsset ? (
          <Box
            className={
              '1024:w-[calc(50%_-_39px)] 1440:w-[calc(50%_-_69px)] 768:mb-0 768:w-[calc(50%_-_27px)] rounded-lg'
            }
            margin={{ bottom: '4' }}
            style={{
              overflow: heroVisualAsset?.mediaType !== 'youtubeVideo' ? 'hidden' : 'visible'
            }}
          >
            {heroVisualAsset?.mediaType ? (
              <VisualAsset {...heroVisualAsset} />
            ) : (
              <NextImage image={image} alt={headerText} layout='responsive' />
            )}
          </Box>
        ) : null}
      </Box>
    </PageSection>
  );
};

HeroLeft.displayName = 'HeroLeft';

export default HeroLeft;

export const buttonsBox = heroLeftStyles.buttonsBox;
export const tertiaryLink = heroLeftStyles.tertiaryLink;
