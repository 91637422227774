import React, { useMemo } from 'react';
import Link from 'adapter/next/link';
import { cn } from '@superside/ui';
import { type TextType } from '../types';
import styles from './Bubble.module.css';
import { BubbleSvg } from './BubbleSvg';

export interface BubbleProps {
  id: number;
  x: number;
  y: number;
  hoverBubble: number | null;
  text1?: TextType;
  text2?: TextType;
  Icon?: React.FC<{ className: string }>;
  bubbleScale?: number;
  href?: string;
  className?: string;
  style?: React.CSSProperties;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  collapsed?: boolean;
  withoutLink?: boolean;
  iconStyles?: {
    normal: string;
    normalDesktop: string;
    hover: string;
    hoverDesktop: string;
    otherHovered: string;
    otherHoveredDesktop: string;
    normalScale: string;
    normalDesktopScale: string;
    hoverScale: string;
    hoverDesktopScale: string;
    otherHoveredScale: string;
    otherHoveredDesktopScale: string;
  };
}

export const Bubble: React.FC<BubbleProps> = ({
  id,
  x,
  y,
  text1,
  text2,
  Icon,
  bubbleScale = 1,
  href = '#',
  hoverBubble,
  onMouseEnter,
  onMouseLeave,
  collapsed,
  iconStyles,
  withoutLink = false,
  ...rest
}) => {
  const isHovered = hoverBubble === id;
  const otherBubbleHovered = hoverBubble !== null && hoverBubble !== id;

  const {
    normal,
    normalDesktop,
    hover,
    hoverDesktop,
    otherHovered,
    otherHoveredDesktop,
    normalScale,
    normalDesktopScale,
    hoverScale,
    hoverDesktopScale,
    otherHoveredScale,
    otherHoveredDesktopScale
  } = iconStyles || {};

  const style = useMemo(
    () => ({
      '--normal': normal,
      '--normalDesktop': normalDesktop,
      '--hover': hover,
      '--hoverDesktop': hoverDesktop,
      '--otherHovered': otherHovered,
      '--otherHoveredDesktop': otherHoveredDesktop,
      '--normalScale': normalScale,
      '--normalDesktopScale': normalDesktopScale,
      '--hoverScale': hoverScale,
      '--hoverDesktopScale': hoverDesktopScale,
      '--otherHoveredScale': otherHoveredScale,
      '--otherHoveredDesktopScale': otherHoveredDesktopScale
    }),
    [
      hover,
      hoverDesktop,
      hoverDesktopScale,
      hoverScale,
      normal,
      normalDesktop,
      normalDesktopScale,
      normalScale,
      otherHovered,
      otherHoveredDesktop,
      otherHoveredDesktopScale,
      otherHoveredScale
    ]
  );

  const content = (
    <>
      {<BubbleSvg scale={bubbleScale} />}
      {!collapsed && (
        <text
          className={cn(
            `${styles.textStyle} fill-light tracking-15 text-bor-xs text-center font-semibold uppercase not-italic transition-[fill] duration-[0.2s] ease-[ease-in-out]`,
            otherBubbleHovered &&
              'fill-black-100/0 transition-[fill] duration-[0.2s] ease-[ease-in-out]'
          )}
        >
          {text1 ? (
            <tspan x={text1.x} y={text1.y}>
              {text1.text}
            </tspan>
          ) : null}
          {text2 ? (
            <tspan x={text2.x} y={text2.y}>
              {text2.text}
            </tspan>
          ) : null}
        </text>
      )}
      {Icon && (
        <g style={style as any}>
          {
            <Icon
              className={cn(
                '768:scale-[var(--normalDesktopScale)] 768:translate-x-[var(--normalDesktop)] translate-x-[var(--normal)] scale-[var(--normalScale)] transition-all duration-200 ease-in-out',
                isHovered &&
                  '768:scale-[var(--hoverDesktopScale)] 768:translate-x-[var(--hoverDesktop)] translate-x-[var(--hover)] scale-[var(--hoverScale)]',
                otherBubbleHovered &&
                  '768:scale-[var(--otherHoveredDesktopScale)] 768:translate-x-[var(--otherHoveredDesktop)] translate-x-[var(--otherHovered)] scale-[var(--otherHoveredScale)]'
              )}
            />
          }
        </g>
      )}
    </>
  );

  return (
    <g {...rest}>
      <g
        style={{
          // @ts-ignore
          '--tx': `${x}px`,
          '--ty': `${y}px`
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={cn(
          styles.wrapperStyle,
          isHovered && styles.hoverWrapperStyle,
          otherBubbleHovered && styles.otherHoveredWrapperStyle
        )}
        data-testid={`bubble-in-${id}`}
      >
        {withoutLink ? content : <Link href={href}>{content}</Link>}
      </g>
    </g>
  );
};

export const hoverWrapperStyle = styles.hoverWrapperStyle;
export const otherHoveredWrapperStyle = styles.otherHoveredWrapperStyle;
export const wrapperStyle = styles.wrapperStyle;
